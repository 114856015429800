export const environment = {
  production: true,
};

export const apiUrl = {
  getCountry: `static/get-all-byId-byCountry-search-data`,
  course: {
    getSeoAndCourseGroup: 'course/v1.1/web/get-seo-and-courseG-group-list',
    searchCourse: 'course/v1.1/web/search-and-filter-course-details',
    matchLogic: 'course/v1.1/web/get-matching-university',
    getAcademicQualification:`course/get-search-all-global-academic-qualification-details`,
    getSubjects: `course/get-search-all-global-subject-details`,
    getResults: 'course/get-allData-serchByName'

  }
}

