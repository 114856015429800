import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section10',
  templateUrl: './section10.component.html',
  styleUrls: ['./section10.component.css']
})
export class Section10Component implements OnInit {

  section10Form : FormGroup;
  description : any = '';
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  @Input() checkRead: any=false;
  universityList: any=[];



  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
  }
  

  initializeForm(){
    this.section10Form = new FormGroup({
      "currentlyFundingForStudy" : new FormControl("false",Validators.required),
      "applyForExternalFunding" : new FormControl("false",Validators.required),
      "externalFundingDescription" : new FormControl(''),
      "applyForUniversityFunding" : new FormControl("false",Validators.required),
      "universityFundingDescription" : new FormControl(''),
      "wishForUniversityScholarship" : new FormControl("false",Validators.required),
      "wishForUniversityScholarshipDescription" : new FormControl('')
    })

    if(this.checkRead == true) {
      this.section10Form.disable()
    }
    if(this.service.formDetails.section10){
      this.section10Form.patchValue({
        "currentlyFundingForStudy" : this.service.formDetails.section10.currentlyFundingForStudy,
        "applyForExternalFunding" : this.service.formDetails.section10.applyForExternalFunding,
        "externalFundingDescription" : this.service.formDetails.section10.externalFundingDescription,
        "applyForUniversityFunding" : this.service.formDetails.section10.applyForUniversityFunding,
        "universityFundingDescription" : this.service.formDetails.section10.universityFundingDescription,
        "wishForUniversityScholarship" : this.service.formDetails.section10.wishForUniversityScholarship,
        "wishForUniversityScholarshipDescription" : this.service.formDetails.section10.wishForUniversityScholarshipDescription,
      })
    }
  }

  continue(type){
    this.section10Form.markAllAsTouched()
    if(this.section10Form.invalid){
      return false;
    }
    this.fillForm(type)
  }

  // on external funding change
  onFundingOptionchange(val) {
    if(val == 'true') {
      this.section10Form.get('externalFundingDescription').setValidators([Validators.required]);
    }else {
      this.section10Form.get('externalFundingDescription').setValue(null);
      this.section10Form.get('externalFundingDescription').setValidators(null);
      this.section10Form.get('externalFundingDescription').setErrors(null);
    }
    this.section10Form.controls['externalFundingDescription'].updateValueAndValidity();
  }

  // on university funding change
  onUniversityOptionchange(val) {
    if(val == 'true') {
      this.section10Form.get('universityFundingDescription').setValidators([Validators.required]);
    }else {
      this.section10Form.get('universityFundingDescription').setValidators(null);
      this.section10Form.get('universityFundingDescription').setValue(null);
      this.section10Form.get('universityFundingDescription').setErrors(null);
    }
    this.section10Form.controls['universityFundingDescription'].updateValueAndValidity();
  }
  
  // on university funding change
  onScholarshipOptionchange(val) {
    if(val == 'true') {
      this.section10Form.get('wishForUniversityScholarshipDescription').setValidators([Validators.required]);
    }else {
      this.section10Form.get('wishForUniversityScholarshipDescription').setValue(null);
      this.section10Form.get('wishForUniversityScholarshipDescription').setValidators(null);
      this.section10Form.get('wishForUniversityScholarshipDescription').setErrors(null);
    }
    this.section10Form.controls['wishForUniversityScholarshipDescription'].updateValueAndValidity();
  }
  
  fillForm(type){
    this.service.formDetails['section10'] = {...this.section10Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section11'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section10'] = {...this.section10Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('scholarship')
  }


}