import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section8',
  templateUrl: './section8.component.html',
  styleUrls: ['./section8.component.css']
})
export class Section8Component implements OnInit {
  @Input() checkRead: any=false;

  section8Form : FormGroup;
  TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom,CountryISO.India];
  SearchCountryField = SearchCountryField;
  validPhoneNo1:Boolean = true
  validPhoneNo2:Boolean = true;
  countryFlag: any;
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];


  constructor(private router:Router,private service:ServicesService) {}

  ngOnInit() {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []
    window.scrollTo(0, 0);
    this.initializeForm()
  }
  

  initializeForm() {
    this.section8Form = new FormGroup({
      refree1name: new FormControl(null,Validators.required),
      refree1title: new FormControl(null,Validators.required),
      refree1address: new FormControl(null,Validators.required),
      refree1phoneNumber : new FormControl(null,Validators.required),
      refree1email : new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      refree1Description : new FormControl(null,Validators.required),
      refree2name: new FormControl(null,Validators.required),
      refree2title: new FormControl(null,Validators.required),
      refree2address: new FormControl(null,Validators.required),
      refree2phoneNumber : new FormControl(null,Validators.required),
      refree2email : new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      refree2Description : new FormControl(null,Validators.required),
    })

    if(this.checkRead == true) {
      this.section8Form.disable()
    }
    if(this.service.formDetails.section3) {
      this.service.getCountryStates().subscribe((res: any) => {
        let countryList = res
          this.countryFlag = (countryList.find((x)=>x.country === this.service.formDetails.section3.country)).shortName
      })
    }
    if(this.service.formDetails.section8){
      this.section8Form.patchValue({
        refree1name: this.service.formDetails.section8.refree1name,
        refree1title: this.service.formDetails.section8.refree1title,
        refree1address: this.service.formDetails.section8.refree1address,
        refree1phoneNumber : this.service.formDetails.section8.refree1phoneNumber,
        refree1email : this.service.formDetails.section8.refree1email,
        refree1Description : this.service.formDetails.section8.refree1Description,
        refree2name: this.service.formDetails.section8.refree2name,
        refree2title: this.service.formDetails.section8.refree2title,
        refree2address: this.service.formDetails.section8.refree2address,
        refree2phoneNumber : this.service.formDetails.section8.refree2phoneNumber,
        refree2email : this.service.formDetails.section8.refree2email,
        refree2Description : this.service.formDetails.section8.refree2Description,
      })
    }
  }

  phoneValidOrNot1(){
    let key = this.section8Form.controls['refree1phoneNumber'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validPhoneNo1 = key['validatePhoneNumber'].valid
      }else{
        this.validPhoneNo1 = true
      }
    }else{
      this.validPhoneNo1 = true
    }
  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  phoneValidOrNot2(){
    let key = this.section8Form.controls['refree2phoneNumber'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validPhoneNo2 = key['validatePhoneNumber'].valid
      }else{
        this.validPhoneNo2 = true
      }
    }else{
      this.validPhoneNo2 = true
    }
  }



  continue(type){
    this.section8Form.markAllAsTouched()
    if(this.section8Form.invalid){
      return false
    }
    this.fillForm(type)
  }




  fillForm(type){
    this.service.formDetails['section8'] = {...this.section8Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section9'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section8'] = {...this.section8Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('reference')
  }
}