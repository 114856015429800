import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, FormControlName } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
import { apiUrl } from 'src/environments/environment.prod';
declare var $:any;
@Component({
  selector: 'app-section4',
  templateUrl: './section4.component.html',
  styleUrls: ['./section4.component.css']
})
export class Section4Component implements OnInit {

  section4Form: FormGroup;
  qualificationArr: FormArray;
  countryList: any = []
  resultTypeList: any = []
  SubjectTypeList: any = []
  academicQualifiationList: any = []
  academicQualificationFormDto: any = []
  submitted: boolean = false
  section1Data: any;
  section2Data: any;
  section3Data: any;
  resultType: any = 'text';
  academicData: any=[];
  minDate: string;
  maxDate: string;
  allResultsArr: any=[];
  charArr:any= ['A+','A-','B+','B-','C+','C-','D+','D-','E+','E-','F+','F-']
  @Input() preview: any=false;
  @Input() checkRead: any=false;
  @Output() messageToEmit = new EventEmitter<string>();
  universityList: any=[];

  constructor(private fb: FormBuilder, private service: ServicesService, private router: Router) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

    this.section4Form = this.fb.group({
      stateHighestQualification: new FormControl(null, Validators.required),
      achieved: new FormControl(null),
      qualificationArr: new FormArray([this.initCall()])
    });

    if(this.service.formDetails.section4) {
      for(let i = 1; i < this.service.formDetails.section4.qualificationArr.length; i++) {
        this.addQualification()
        for(let j = 1; j < this.service.formDetails.section4.qualificationArr[i-1].subjectArr.length; j++) {
          this.addSubject(i-1)
        }
      } 
    }
    this.dateValidation()
    this.getResults()
    if(this.checkRead == true) {
      this.section4Form.disable()
    }
  }
  

  // to validate form 
  dateValidation() {
    let date = new Date();
    let day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
    let year = date.getFullYear();
    // this.maxDate = (year - 5) + '-'  + month + '-' +  day;
    this.minDate = (year-1) + '-' + month + '-' + day;
    console.log(this.minDate)
  }

  // to get results
  getResults() {
    let url = apiUrl.course.getResults + `?page=0&pageSize=9999`;
    this.service.getAPI(url).subscribe(res=>{
      if(res.body.status == 200) {
        this.allResultsArr = res.body.data.allData.content
      }
    })
  }

  // to initialise form 
  initCall() {
    return new FormGroup({
      country: new FormControl('', Validators.required),
      qualification: new FormControl('', Validators.required),
      qualificationArr: new FormControl([], Validators.required),
      resultGrade: new FormControl(''),
      resultArr: new FormControl([], Validators.required),
      overallGrade: new FormControl(''),
      overallGradeArr: new FormControl([], Validators.required),
      subjectArr : new FormArray([this.initSubjectForm()]),
      institutionName: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
    });
  }

  // to initialise subject form
  initSubjectForm() {
    return new FormGroup({
      // country: new FormControl(null, Validators.required),
      // qualification: new FormControl(null, Validators.required),
      // resultGrade: new FormControl(null, Validators.required),
      // subjectArr : new FormArray([this.initCall()]),
      subject: new FormControl('', Validators.required),
      subjectGrade: new FormControl('', Validators.required),
      // institutionName: new FormControl(null, Validators.required),
      // startDate: new FormControl(null, Validators.required),
      // endDate: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getSubjects();
    this.getAcademicData()
  }

  // to get subjects
  getSubjects() {
    let url = apiUrl.course.getSubjects+ `?page=0&pagesize=9999`
    this.service.getApi(url,1).subscribe((res:any)=>{
      if(res.body.status == 200) {
        this.SubjectTypeList = res.body.data.getAllData.content
        this.SubjectTypeList.sort((a,b) => (a.subjectName > b.subjectName) ? 1 : ((b.subjectName > a.subjectName) ? -1 : 0))

        if(this.service.formDetails.section4) {
          this.patchForm()
         }
      }
    })
  }

  // to get academic data
  getAcademicData() {
    // this.service.showSpinner();
    let url = apiUrl.course.getAcademicQualification + `?page=0&pagesize=9999&isBand=false`
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      this.service.hideSpinner()
      if(res.body.status == 200) {
        let arr =[]
        this.academicData = res.body.data.getAllData.content
        this.academicData.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))

        this.academicData.forEach(ele => {
          arr.push(ele.country)
        });

       this.countryList = [...new Set(arr)];
       this.countryList.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
       if(this.service.formDetails.section4) {
        this.patchForm()
       }
      }
    })
  }

  // to patch values
  patchForm() {
    this.section4Form.patchValue(this.service.formDetails.section4)
  }

  // to get Academic Qualifications of that country
  getAcademicQualification(country,valIndex) {
    let arr = []
    this.academicData.forEach((ele,index) => {
      if(ele.country == country)  {
        arr.push(ele)
      }
    });

    let myForm = (<FormArray>this.section4Form.get("qualificationArr")).at(valIndex);
    myForm.patchValue({
      qualificationArr: [...arr]
    });
  }

  // on select academic data
  OnSelectAcademicQualification(code,data,valIndex) {
    this.resultTypeList = []
    let obj = data.value.qualificationArr.find(x=>x.academicQualificationCode == code);
    const myForm = (<FormArray>this.section4Form.get("qualificationArr")).at(valIndex);
    myForm.patchValue({
      resultArr: obj.resultTypeList.split(',')
    });
  }

  // to add qualification
  addQualification(): void {
    this.qualificationArr = this.section4Form.get('qualificationArr') as FormArray;
    this.qualificationArr.push(this.createCall());
    console.log("form data-->", this.qualificationArr.value)
  }

  createCall(): FormGroup {
    return this.fb.group({
      country: new FormControl('', Validators.required),
      qualification: new FormControl('', Validators.required),
      qualificationArr: new FormControl([], Validators.required),
      resultGrade: new FormControl('', Validators.required),
      resultArr: new FormControl([], Validators.required),
      overallGrade: new FormControl('', Validators.required),
      overallGradeArr: new FormControl([], Validators.required),
      subjectArr : new FormArray([this.initSubjectForm()]),
      institutionName: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required)
    });
  }

  // to add Subject
  addSubject(index): void {
    let subjectArr = this.section4Form.controls['qualificationArr']['controls'].subjectArr as FormArray;
    console.log("form data-->", subjectArr)

    let fg = this.fb.group({
      'subject': new FormControl('', Validators.required),
      'subjectGrade': new FormControl('', Validators.required)
    });
    (<FormArray>(<FormGroup>(<FormArray>this.section4Form.controls['qualificationArr'])
            .controls[index]).controls['subjectArr']).push(fg);
  }

  deletePhone(userIndex: number, index: number) {
    (<FormArray>(<FormGroup>(<FormArray>this.section4Form.controls['qualificationArr'])
        .controls[userIndex]).controls['subjectArr']).removeAt(index);
  }

  // to remove data at given index
  remove(index){
    this.qualificationArr.removeAt(index);
    this.academicQualifiationList.splice(index,1)
  }

  cancel() {
    this.section4Form = this.fb.group({
      qualificationArr: this.fb.array([])
    });
    this.addQualification()
  }

  continue(type) {
    this.submitted = true;
    this.section4Form.markAllAsTouched()
    if (this.section4Form.invalid) {
      return false
    }
    this.fillForm(type)
  }

  fillForm(type){
    this.service.formDetails['section4'] = {...this.section4Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section5'])
      return;
    }
  
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onResultChange(value,valIndex) {
    let gradeArr = []
    
    let obj = this.allResultsArr.find((x)=>x.resultName == value)
    console.log(obj)
    if(obj.datatype == "Points" || obj.datatype == "Number") {
      for(let i = Number(obj.minRanange); i <= Number(obj.maxRange); i=i+ Number(obj.overAllInterval) ) {
        gradeArr.push((Number(i)).toFixed(1))
      }
    }else if(obj.datatype == "Character"){
      let start_index = this.charArr.findIndex(x=>x == obj.maxRange)
      let final_index = this.charArr.findIndex(x=>x == obj.minRanange)
      if(start_index != -1 && final_index != -1) {
        for(let i= start_index; i <= final_index; i++) {
          gradeArr.push(this.charArr[i])
        }
      }
    }
    let myForm = (<FormArray>this.section4Form.get("qualificationArr")).at(valIndex);
    myForm.patchValue({
      overallGradeArr:[...gradeArr]
    })
  }

  onPreviewSave() {
    this.service.formDetails['section4'] = {...this.section4Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('academicQualification')
  }


}