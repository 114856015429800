import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ServicesService } from 'src/app/services.service';
declare var $:any

@Component({
  selector: 'app-section13',
  templateUrl: './section13.component.html',
  styleUrls: ['./section13.component.css']
})
export class Section13Component implements OnInit {
  section13Form: any={};
  countryFlag:any
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  separateDialCode = true;
  validPhoneNo:Boolean = true
  @Input() checkRead: any=false;


  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];


  constructor(private router:Router, public service: ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
  }

  initializeForm(){
    this.section13Form = new FormGroup({
      "currentEmployment" : new FormControl(null,Validators.required),
      "jobTitle" : new FormControl(null,Validators.required),
      "employerName" : new FormControl(null,Validators.required),
      "address" : new FormControl(null,Validators.required),
      "telephoneNo" : new FormControl(null,Validators.required),
      "employementEmail" : new FormControl(null,Validators.required),
      "employeeBusiness" : new FormControl(null,Validators.required),
      "dateAppointed" : new FormControl(null,Validators.required),
      "annualSalary" : new FormControl(null,Validators.required),
      "description" : new FormControl(null,Validators.required)
    })
    if(this.checkRead == true) {
      this.section13Form.disable()
    }
    this.service.getCountryStates().subscribe((res: any) => {
      let countryList = res
      if(this.service.formDetails.section3){
        this.countryFlag = (countryList.find((x)=>x.country === this.service.formDetails.section3.country)).shortName
      }
    })
    if(this.service.formDetails.section13){
      this.section13Form.setValue(this.service.formDetails.section13)
    }
  }

  phoneValidOrNot(){
    let key = this.section13Form.controls['telephoneNo'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validPhoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validPhoneNo = true
      }
    }else{
      this.validPhoneNo = true
    }
  }

  continue(type){
    this.section13Form.markAllAsTouched()
    if(this.section13Form.invalid) {
      return;
    }
    this.service.formDetails['section13'] = {...this.section13Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      if(this.service.formDetails.section1.courseName == 'MBA')  {
        this.router.navigate(['section15'])
      }
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section13'] = {...this.section13Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('academicQualification')
  }

}
