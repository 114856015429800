import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-draft-form',
  templateUrl: './draft-form.component.html',
  styleUrls: ['./draft-form.component.css']
})
export class DraftFormComponent implements OnInit {

  searchKey : any;
  filterBy : any;
  fromDate : any;
  toDate : any;
  formsList:any = []
  accountDeatails: any;
  maxFromDate: string;
  maxToDate: string;
  minToDate: any;
  type: any='INCOMPLETE';
  page:any=1;
  total:any=0

  constructor(private service:ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.accountDeatails = JSON.parse(localStorage.getItem('myProfile'))
    this.getForms(this.type)
  }

  onFromChangeDate(){
    this.minToDate = this.fromDate;
  }
  onToChangeDate(){
    this.maxFromDate = this.toDate;
  }

  onFilterSelect(event) {
    this.filterBy = event.target.value;
  }

  dateValidation(){
    let date = new Date();
    let currentDay = date.getDate() >= 10 ? date.getDate(): '0'+ date.getDate();
    let currentMonth = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1): '0'+date.getMonth();
    let currentYear = date.getFullYear();
    this.maxFromDate = currentYear + '-' + currentMonth + '-' + currentDay;
    this.maxToDate = currentYear + '-' + currentMonth + '-' + currentDay;


  }

  getForms(type){
    this.formsList = []
    this.type=type
    // let url = `course/filter-forms-details?page=0&formFillStatus=COMPLETE&representativeId=${this.accountDetails.representativeDetailsId}`
    let url = `application/get-forms?currentStatus=${this.type}&page=${this.page-1}&pageSize=10&role=REPRESENTATIVE`
    if(this.filterBy && this.searchKey){
      if(this.filterBy == 'search_by_form_id') {
        url = url + `&formId=${this.searchKey}`
      }else if(this.filterBy == 'submitted_by') {
        url = url + `&submitedBy=${this.searchKey}`
      }else if(this.filterBy == 'candidateName') {
        url = url + `&candidateName=${this.searchKey}`
      }
    }
    this.service.getApi(url,1).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.body.status == 205 || res.body.status == 200){
        this.formsList = res.body.data
        this.formsList.forEach(ele => {
          ele['parsedData'] = JSON.parse(ele.formData)
        });
        this.total = res.body.data.totalCount
      }
    })
  }
  convertIntoTimeStamp(myDate) {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    console.log(new Date(newDate).getTime());
    return (new Date(newDate).getTime())
  }

  reset(){
    this.searchKey = undefined
    this.filterBy = undefined
    this.fromDate = undefined
    this.toDate = undefined
    this.getForms(this.type)
  }

  continueForm(item) {
    localStorage.setItem('formId',item.formId)
    localStorage.setItem('formDetails',item.formData)
    if(item.parsedData.section11 || item.parsedData.section10) {
      this.router.navigate(['section11'])
    }else if(item.parsedData.section9) {
      this.router.navigate(['section10'])
    }else if(item.parsedData.section8) {
      this.router.navigate(['section9'])
    }else if(item.parsedData.section7) {
      this.router.navigate(['section8'])
    }else if(item.parsedData.section6) {
      this.router.navigate(['section7'])
    }else if(item.parsedData.section5) {
      this.router.navigate(['section6'])
    }else if(item.parsedData.section4) {
      this.router.navigate(['section5'])
    }else if(item.parsedData.section3) {
      this.router.navigate(['section4'])
    }else if(item.parsedData.section2) {
      this.router.navigate(['sectio3'])
    }else if(item.parsedData.section1) {
      this.router.navigate(['section2'])
    }
  }

  // continueForm(id){
  //     this.service.showSpinner()
  //     this.service.getApi(`course/get-forms-list?page=0&pagesize=10&formId=${id}&userId=${this.accountDeatails.userId}`,1).subscribe((res:any) => {
  //         this.service.hideSpinner()
  //         if(res.status == 200){
  //             let formData = res.body.data.formdata
  //             let section1_Object = {
  //                 "searchCourse":formData.courseName,
  //                 "countryName":formData.courseCountry,
  //                 "startYear":formData.courseSttartDate ? formData.courseSttartDate.split('-')[0] : '',
  //                 "startMonth":formData.courseSttartDate ? formData.courseSttartDate.split('-')[1] : '',
  //                 "yearIntake":"",
  //                 "courseId":formData.courseId,
  //                 "courseStartDate":formData.courseSttartDate
  //             }
  //             let section2_Object = {
  //                 "CountryOfBirth" :formData.countryOfBirth,
  //                 "descriptionForCriminalConviction" :formData.criminalDescription,
  //                 "descriptionForDisablity" :formData.description,
  //                 "disability" :formData.disability,
  //                 "requireVisaForUK" :formData.doUoyRequireVisatoStudtInTheUk  ,
  //                 "dateOfBirth" :formData.dob ,
  //                 "email" :formData.email,
  //                 "confirmEmail" :formData.email,
  //                 "ethnicity" :formData.ethenticity,
  //                 "foreName" :formData.forname,
  //                 "gender" :formData.gender,
  //                 "passportNumber" :formData.passportNumber,
  //                 "permanentResidenceCountry" :formData.permanentResidenceCountry,
  //                 "prefferedName" :formData.preferredName,
  //                 "criminalConviction" :formData.relevantCriminalConvictions,
  //                 "surName" :formData.sirName,
  //                 "title" :formData.title,
  //             }
  //             let section3_Object = {
  //                 "address"  :formData.address ,
  //                 "email"  :formData.contactEmail ,
  //                 "country"  :formData.country ,
  //                 "homeAddress"  :formData.homeAddress ,
  //                 "homeEmail"  :formData.homeEmail  ,
  //                 "homeTelephoneNo"  :formData.homeTeliphoneNo  ,
  //                 "homeTelephoneNo1"  :formData.homeTeliphoneNo2 ,
  //                 "state"  :formData.state ,
  //                 "telephoneNo"  :formData.telephoneNo  ,
  //                 "contactPhoneNo"  :formData.telephoneNo ,
  //                 "zipCode" :formData.zipcode ,
  //                 "checkForHomeAddress" : false ,          
  //             }
  //             let section4_Object = {
  //                 "achieved" : formData.academicQualificationForm ? formData.academicQualificationForm.achieved : '',
  //                 "stateHighestQualification": formData.academicQualificationForm ? formData.academicQualificationForm.highestAcademicQualification : '',
  //                 "qualificationArr" : []
  //             }
  //               section4_Object.qualificationArr.push({
  //                 "qualification" : formData.academicQualificationForm ? formData.academicQualificationForm.academicQualifications : '',
  //                 "country" : formData.academicQualificationForm ? formData.academicQualificationForm.countryOfStudy : '',
  //                 "endDate" : formData.academicQualificationForm ? formData.academicQualificationForm.enddate ? formData.academicQualificationForm.enddate.split('T')[0] : '' : '', 
  //                 "institutionName" : formData.academicQualificationForm ? formData.academicQualificationForm.instituteName  : "",
  //                 "resultGrade" : formData.academicQualificationForm ? formData.academicQualificationForm.resultType  : '',
  //                 "startDate" : formData.academicQualificationForm ? formData.academicQualificationForm.startDate ? formData.academicQualificationForm.startDate.split('T')[0] : '' : '',
  //                 "subject1" : formData.academicQualificationForm ? formData.academicQualificationForm.subject1  : "",
  //                 "subject1Grade" : formData.academicQualificationForm ? formData.academicQualificationForm.subject1grade1 : '',
  //                 "subject2" : formData.academicQualificationForm ? formData.academicQualificationForm.subject1grade2 : '',
  //                 "subject2Grade" : formData.academicQualificationForm ? formData.academicQualificationForm.subject2  : '',
  //               })
  //             let section5_Object = {
  //                 "resultType": "",
  //                 "englishQualificationDate": "",
  //                 "nativeOfEnglishSpeakingCountry"  : formData.areUNativeOfEnglishSpeakingCountry,
  //                 "professionalQualification"  : formData.doUHaveprofessionalQualification,
  //                 "englishLanguageTestDetails" : formData.ifNoThenAddAboutRecentEnglishLanguage,
  //                 "highestAcedemicQualification" : formData.ifNoThenAddHighestEnglishQualification,
  //                 "nativeEnglishSpeakingCountryName" : formData.ifYesSelectCountryForSpeakingCountry,
  //                 "englishQualificationName" : formData.ifYesThenAddProfessionalQualification,
  //                 "englishQualificationScore1" : formData.indivisualBandScore1,
  //                 "englishQualificationScore2" : formData.indivisualBandScore2,
  //                 "englishQualificationScore3" : formData.indivisualBandScore3,
  //                 "englishQualificationScore4" : formData.indivisualBandScore4,
  //                 "overallResult" : formData.overallResultForEnglishQualification,
  //                 "typeOfEnglishQualification" : formData.typeOfEnglishQualification,          
  //             }
  //             let section6_Object = {
  //                 "date" : formData.awardedDate ? formData.awardedDate.split('T')[0] : '',
  //                 "proffessionalBody" : formData.professionalBodyMembership,
  //                 "subject" : formData.professionalQualificationSubject,
  //                 "title" : formData.qualificationTitle
  //             }
  //             let section7_Object = {
  //                 "refree1Description" : formData.aboutReference1,
  //                 "refree2Description" : formData.aboutReference2,
  //                 "refree1address" : formData.referee1Address,
  //                 "refree1name" : formData.referee1Name,
  //                 "refree1phoneNumber" : formData.referee1TelephoneNumber,
  //                 "refree1title" : formData.referee1Title,
  //                 "refree2address" : formData.referee2Address,
  //                 "refree2name" : formData.referee2Name,
  //                 "refree2phoneNumber" : formData.referee2TelephoneNumber,
  //                 "refree2title" : formData.referee2Title,
  //                 "refree1email" : formData.referee1Email,
  //                 "refree2email" : formData.referee2Email,
  //             }
  //             let section8_Object = {
  //                 "uploadState" : formData.personalStatementDescription == '' ? true : false,
  //                 "description": formData.personalStatementDescription
  //             }
  //             let section9_Object = {
  //                 "applyForExternalFunding" : formData.applyForExternalFunding ? formData.applyForExternalFunding : 'true',
  //                 "externalFundingDescription" : formData.applyForExternalFundingDescription ? formData.applyForExternalFundingDescription : "true",
  //                 "currentlyFundingForStudy" : formData.doYouCurrentlyHaveFundingForYourChosenProgrammeofStudy ? formData.doYouCurrentlyHaveFundingForYourChosenProgrammeofStudy : "true",
  //                 "applyForUniversityFunding" : formData.wishtoApplyForUniversityFunding ? formData.wishtoApplyForUniversityFunding : "true",
  //                 "universityFundingDescription" : formData.wishtoApplyForUniversityFundingDescription ? formData.wishtoApplyForUniversityFundingDescription : "true",
  //                 "wishForUniversityScholarship" : formData.wishtoApplyForUniversityscholarship ? formData.wishtoApplyForUniversityscholarship : "true",
  //                 "wishForUniversityScholarshipDescription" : formData.wishtoApplyForUniversityscholarshipDescription ? formData.wishtoApplyForUniversityscholarshipDescription : "true",
  //             }
  //             let section10_Object = {
  //                 "researchProposal" : ("" +formData.researchProposalForPGR),
  //                 "researchProposalDescription" :formData.researchProposalDescription
  //             }
  //             let section11_Object = {
  //                 "totalExperience" : formData.totalWorkExperience ? formData.totalWorkExperience : 0,
  //                 "briefDuties" : formData.briefDuties ? formData.briefDuties : '',
  //                 "employedFrom" : formData.dateAppointed ? formData.dateAppointed : '',
  //                 "employersBusiness" : formData.employerName ? formData.employerName : '',
  //                 "employersName" : formData.employersName ? formData.employersName : '',
  //                 "annualSalary" : formData.grossAnnualSalary ? formData.grossAnnualSalary : '0',
  //                 "jobTitle" : formData.pgtJobTitle ? formData.pgtJobTitle : '',
  //                 "employedTo":'',
  //                 "employersAddress":'',
  //                 "email":'',
  //                 "achievements":'',
  //                 "workExperience" : formData.totalWorkExperience ?( formData.workExperience > 0 ? "true": "false" ): "false",
  //             }
  //             let section12_Object = {
  //                 "cv" : formData.urlCv,
  //                 "degreeCertificate" : formData.urlDegree,
  //                 "otherDocument" : formData.urlDocuments,
  //                 "englishLanguageCertificate" : formData.urlEnglishLanguageCertificate,
  //                 "researchPersonal" : formData.urlResearchProposal,
  //                 "disclaimer" : formData.urlStatement,
  //                 "transcript" : formData.urlTranscript,
  //                 "personalStatement" : formData.urlpersonalStatement,
  //             }
  //             if(formData.pageFillNumber == 'section1'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //             } else if(formData.pageFillNumber == 'section2'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //             } else if(formData.pageFillNumber == 'section3'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //             } else if(formData.pageFillNumber == 'section4'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //             } else if(formData.pageFillNumber == 'section5'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //             } else if(formData.pageFillNumber == 'section6'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //             } else if(formData.pageFillNumber == 'section7'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //             } else if(formData.pageFillNumber == 'section8'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //                 localStorage.setItem('section8',JSON.stringify(section8_Object))
  //             } else if(formData.pageFillNumber == 'section9'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //                 localStorage.setItem('section8',JSON.stringify(section8_Object))
  //                 localStorage.setItem('section9',JSON.stringify(section9_Object))
  //             } else if(formData.pageFillNumber == 'section10'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //                 localStorage.setItem('section8',JSON.stringify(section8_Object))
  //                 localStorage.setItem('section9',JSON.stringify(section9_Object))
  //                 localStorage.setItem('section10',JSON.stringify(section10_Object))
  //             } else if(formData.pageFillNumber == 'section11'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //                 localStorage.setItem('section8',JSON.stringify(section8_Object))
  //                 localStorage.setItem('section9',JSON.stringify(section9_Object))
  //                 localStorage.setItem('section10',JSON.stringify(section10_Object))
  //                 localStorage.setItem('section11',JSON.stringify(section11_Object))
  //             } else if(formData.pageFillNumber == 'section12'){
  //                 localStorage.setItem('section1',JSON.stringify(section1_Object))
  //                 localStorage.setItem('section2',JSON.stringify(section2_Object))
  //                 localStorage.setItem('section3',JSON.stringify(section3_Object))
  //                 localStorage.setItem('section4',JSON.stringify(section4_Object))
  //                 localStorage.setItem('section5',JSON.stringify(section5_Object))
  //                 localStorage.setItem('section6',JSON.stringify(section6_Object))
  //                 localStorage.setItem('section7',JSON.stringify(section7_Object))
  //                 localStorage.setItem('section8',JSON.stringify(section8_Object))
  //                 localStorage.setItem('section9',JSON.stringify(section9_Object))
  //                 localStorage.setItem('section10',JSON.stringify(section10_Object))
  //                 localStorage.setItem('section11',JSON.stringify(section11_Object))
  //                 localStorage.setItem('section12',JSON.stringify(section12_Object))
  //             }                                
  //             localStorage.setItem('formId',id)
  //             this.router.navigateByUrl(formData.pageFillNumber)
  //         }
  //     })
  // }

}