import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import {Subject } from 'rxjs';
import {debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { apiUrl } from 'src/environments/environment.prod';
declare var $: any

@Component({
  selector: 'app-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.css']
})
export class Section1Component implements OnInit {

  section1Form: FormGroup;
  @Input() preview: any=false;
  @Input() checkRead: any=false;
  @Output() messageToEmit = new EventEmitter<string>();
  searchCourse$: Subject<any> = new Subject();
  countryList: any = [];
  courseList: any = [];
  yearList: any = [];
  MonthList: any = []
  filteredCountryOptions: any = [];
  filteredOptions: any = [];
  courseDuration: any
  countryArr: any = [];
  searchKey: any = '';
  search: Subject<any> = new Subject();
  sub: any;
  selectedSubject: any = '';
  qualificationList: any = [];
  selectedQualification: any = "";
  selectedCountry: any;
  searchCourseList: any = [];
  universityList: any=[];



  constructor(public service: ServicesService, private router: Router) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    }
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []
   }

  ngOnInit() {
    this.getCountry()
    this.subscribeSearchSubject()
    this.initializeForm()
    window.scrollTo(0, 0);
  }

  //------To get country list
  getCountry() {
    let url = apiUrl.getCountry + `?page=0&pageSize=999`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res => {
      this.service.hideSpinner();
      if (res.body.status == 200) {
        this.countryArr = res.body.data.allData.content;
        this.filteredCountryOptions = [...this.countryArr]
        if (this.service.formDetails.section1) {
          this.setQualificationType(this.service.formDetails.section1.courseCountry)
        }
      }
    })
  }

  //to initialise form
  initializeForm() {
    this.section1Form = new FormGroup({
      "searchSubject": new FormControl('', [Validators.required]),
      "searchCountry": new FormControl('', [Validators.required]),
      "searchQualification": new FormControl('', [Validators.required]),
      "startYear": new FormControl('', [Validators.required]),
      "startMonth": new FormControl('', [Validators.required]),
      "duration": new FormControl(null),
    })
    if (this.service.formDetails.section1) {
      this.section1Form.patchValue({
        "searchSubject": this.service.formDetails.section1.courseName,
        "searchCountry": this.service.formDetails.section1.courseCountry,
        "searchQualification": this.service.formDetails.section1.qualificationType,
      })
      this.selectedCountry = this.service.formDetails.section1.courseCountry
      this.selectedQualification = this.service.formDetails.section1.qualificationType
      this.selectedSubject = this.service.formDetails.section1.courseName
      this.setFilteredData()
      this.getCourseIntake()
    }
    if(this.checkRead == true) {
      this.section1Form.disable()
    }
  }

  //to patch value in filtered options if data is stored in localStorage
  setFilteredData() {
    this.filteredOptions = []
    let url = apiUrl.course.getSeoAndCourseGroup + `?search=${this.selectedSubject}&page=0&pageSize=10`;
      let api = this.service.getApi(url, 2).subscribe((res: any) => {
        api.unsubscribe();
        this.service.showSpinner()
        if (res.body.status == 200 && res.body.data) {
          this.service.hideSpinner()
          res.body.data.forEach(element => {
            if (element.seoKeyName) {
              this.filteredOptions.push({
                'value': element.seoKeyName,
                'type': 'SEO',
                'code': element.seoKeyCode
              })
            }
          });
        }   
      })
  }

  // to save form
  continue(type) {
    this.section1Form.markAllAsTouched();
    if (this.section1Form.invalid) {
      this.service.toastErr('Form not valid!')
      return 
    }
    this.fillForm(type)
  }

  fillForm(type) {
    // this.service.formDetails['applicationStatus'] = 'DECISION_PENDING';
    // this.service.formDetails['formFillStatus'] = 'INCOMPLETE';
    this.service.formDetails['representativeId'] = JSON.parse(localStorage.getItem('myProfile')).representativeDetailsId;
    this.service.formDetails['representativeName'] = JSON.parse(localStorage.getItem('myProfile')).representativeName;
    this.service.formDetails['representativeEmail'] = JSON.parse(localStorage.getItem('myProfile')).email;
    console.log(this.filteredOptions)
    console.log(this.selectedSubject)
    this.service.formDetails['section1'] = {
      "courseCountry": this.section1Form.value.searchCountry,
      "courseName": this.section1Form.value.searchSubject,
      "courseStartYear": this.section1Form.value.startYear,
      "courseStartMonth": this.section1Form.value.startMonth,
      "qualificationType": this.selectedQualification,
      "subjectCode":(this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())).code
    }

    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    // if type is next
    if(type == 'next') {
      this.router.navigateByUrl('search-results');
      return
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  // to continue filling form
  // continue() {
  //   if(this.section1Form.invalid) {
  //     this.service.toastErr('Form not valid!')
  //     return;
  //   }
  //   let obj = {...this.section1Form.value,subjectCode:(this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())).code}
  //   localStorage.setItem('section1', JSON.stringify(obj))
    
  //   this.router.navigateByUrl('search-results');
  // }

  // to get course intake
  getCourseIntake() {
    this.yearList = [];
    this.MonthList = [];
    let url = `course/v1.1/web/filter-course-intake-years?country=${this.section1Form.value.searchCountry}&page=0&pageSize=999`;
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res.body.status == 200) {
        let arr  = res.body.data.course.content
        arr.map(ele=>{
          this.yearList.push(ele.year)
          this.MonthList.push(ele.month)
        })
        this.yearList = [...new Set(this.yearList)]
        this.MonthList = [...new Set(this.MonthList)]
        
        if (this.service.formDetails.section1) {
          
          this.section1Form.patchValue({
            "startYear": this.service.formDetails.section1.courseStartYear,
            "startMonth": this.service.formDetails.section1.courseStartMonth
          })
        }


      }
    })
  }

  //to search with search results
  searchResult(evt) {
    this.filteredOptions = [];
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let value = evt.target.value;
    if (charCode == 13 || (charCode == 8 && this.searchKey.length == 0) || charCode == 16) {
      return;
    } else {
      let string = value.replace(/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, "");
      if (this.searchKey != string.trim()) {
        this.searchCourse$.next(string.trim());
      }
      if (string == '' && value != '') {
        this.service.toastErr(value + ' not allowed')
      }
      evt.target.value = string
    }
  }

  //-----TO SEARCH COURSE---------------------------------------------------------------------------------------------
  subscribeSearchSubject() {
    this.filteredOptions = []
    this.sub = this.searchCourse$.pipe(debounceTime(1000)).subscribe((search) => {
      this.selectedSubject = ''
      this.searchKey = search
      if (this.searchKey.length == 0) {
        return;
      }
      let arr = []
      if (search.includes('#')) {
        arr = search.split('#')
        search = arr.join("")
      }
      if (!search) {
        return;
      }

      let url = apiUrl.course.getSeoAndCourseGroup + `?search=${search}&page=0&pageSize=10`;
      let api = this.service.getApi(url, 2).subscribe((res: any) => {
        api.unsubscribe();
        console.log(res)
        if (res.body.status == 200 && res.body.data) {
          console.log('called')
          res.body.data.forEach(element => {
            if (element.seoKeyName) {
              this.filteredOptions.push({
                'value': element.seoKeyName,
                'type': 'SEO',
                'code': element.seoKeyCode,
                'duration': element.duration ? element.duration : 0
              })
            }
          });
          if (this.filteredOptions.length != 0) {
            this.searchKey = null
          }
          
        } else {
          this.searchKey = null
          this.filteredOptions = [];
          this.service.toastErr(res.message);
        }
      })
    })
  }

  // To set qualification type
  setQualificationType(value) {
    console.log(value)
    console.log(this.filteredCountryOptions)
    this.qualificationList = (this.filteredCountryOptions.find(x => x.name == value)).countryTypeQualification.split(',')
    this.selectedCountry = value
    this.getCourseIntake()
  }

  // to get selected qualification type
  getSelectedQualificationType(value) {
    this.selectedQualification = value
  }

  // to search course using subject
  searchCourse() {
    this.searchCourseList = [];
    let url = apiUrl.course.searchCourse + `?page=0&pageSize=999`
    if (this.selectedSubject) {
      let obj = this.filteredOptions.find(x => x.value.trim() == this.selectedSubject.trim())
      url = url + `&search=${obj.code}`
    }
    if (this.selectedCountry) {
      url = url + `&country=${this.selectedCountry}`
    }
    if (this.selectedQualification) {
      url = url + `&qualification=${this.selectedQualification}`
    }
    this.service.getApi(url, 2).subscribe((res: any) => {
      if (res.status == 200) {
        this.searchCourseList = res.data.list;
        if (this.searchCourseList.length == 0) {
          this.service.toastErr('No course found!')
        }
      } else {
        this.service.toastErr(res.message)
      }
    }, (err: any) => {
      console.log(err)
      this.service.toastErr(err.error.error)
    })
  }

  //on select subject
  onSubjectSelect(value) {
    this.selectedSubject = value
    let obj = this.filteredOptions.find(x=>x.value == this.selectedSubject)
    this.section1Form.patchValue({'duration': obj.duration})
  }

  onPreviewSave() {
    this.service.formDetails['section1'] = {
      "courseCountry": this.section1Form.value.searchCountry,
      "courseName": this.section1Form.value.searchSubject,
      "courseStartYear": this.section1Form.value.startYear,
      "courseStartMonth": this.section1Form.value.startMonth,
      "qualificationType": this.selectedQualification,
      "subjectCode":(this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())).code
    }
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))  
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('courseAppliedFor')
  }

  // to unsubscribe 
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}