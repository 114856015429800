import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-application-form-preview',
  templateUrl: './application-form-preview.component.html',
  styleUrls: ['./application-form-preview.component.css']
})
export class ApplicationFormPreviewComponent implements OnInit {
  section1 :any = {}
  section2: any;
  showPreview : boolean=true;
  readOnly : boolean=false;
  obj : any={
    courseAppliedFor : 'hidden',
    personalDetails : 'hidden',
    addressDetails : 'hidden',
    academicQualification: 'hidden',
    professionalQualification: 'hidden',
    englishQualification: 'hidden',
    reference: 'hidden',
    personalStatement: 'hidden',
    scholarship: 'hidden',
    researchProposal: 'hidden',
    pgtEmployment: 'hidden',
    docUpload : 'hidden',
    fullTimeMBA : 'hidden',
    executiveMBA : 'hidden',
  }

  constructor(private router:Router, public server:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.server.formDetails = JSON.parse(localStorage.getItem('formDetails'))
      this.section1 = this.server.formDetails.section1
      this.section2 = this.server.formDetails.section2
    } 
   }

   closeDetails(val) {
    this.obj ={
      courseAppliedFor : 'hidden',
      personalDetails : 'hidden',
      addressDetails : 'hidden',
      academicQualification: 'hidden',
      professionalQualification: 'hidden',
      englishQualification: 'hidden',
      reference: 'hidden',
      personalStatement: 'hidden',
      scholarship: 'hidden',
      researchProposal: 'hidden',
      pgtEmployment: 'hidden',
      docUpload : 'hidden',
      fullTimeMBA : 'hidden',
      executiveMBA : 'hidden',
    }
    this.obj[val] = 'show'
   }

  ngOnInit() {
    window.scrollTo(0,0);
    if(localStorage.getItem('section1')) {
      this.section1 = JSON.parse(localStorage.getItem('section1'))
    }
    if(localStorage.getItem('section2')) {
      this.section2 = JSON.parse(localStorage.getItem('section2'))
    }
  }

  getMessage(val) {
    this.obj[val] = 'hidden'
    if(localStorage.getItem('formDetails')) {
      this.server.formDetails = JSON.parse(localStorage.getItem('formDetails'))
      this.section1 = this.server.formDetails.section1
      this.section2 = this.server.formDetails.section2
    } 
  }


  back(){
    // if(this.mbaExist && !this.executiveMbaExist){
    //   this.router.navigateByUrl('section13')
    // }else if(this.executiveMbaExist){
    //   this.router.navigateByUrl('section14')
    // }else{
    //   this.router.navigateByUrl('section12')
    // }
    this.router.navigateByUrl('section15')
  }
}
