import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-section3',
  templateUrl: './section3.component.html',
  styleUrls: ['./section3.component.css']
})
export class Section3Component implements OnInit {

  section3Form:FormGroup
  submitted:boolean = false;
  countryList:any = []
  stateList:any = []
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom,CountryISO.India];
  validPhoneNo:Boolean = true
  validContactNo:Boolean = true;
  validHomeTelephoneNo:Boolean = true;
  validHome1TelephoneNo:Boolean = true;
  responseMessage :any = ""
  section1Data: any;
  section2Data: any;
  shortName: any;
  @Input() preview: any=false;
  @Input() checkRead: any=false;
  @Output() messageToEmit = new EventEmitter<string>();
  universityList: any=[];


  constructor(private service:ServicesService,private router:Router) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []
   }

  ngOnInit() {
    this.initializeForm()  
    window.scrollTo(0, 0);
  }


  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  phoneValidOrNot(){
    let key = this.section3Form.controls['telephoneNo'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validPhoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validPhoneNo = true
      }
    }else{
      this.validPhoneNo = true
    }
  }

  contactValidOrNot(){
    let key = this.section3Form.controls['contactPhoneNo'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validContactNo = key['validatePhoneNumber'].valid
      }else{
        this.validContactNo = true
      }
    }else{
      this.validContactNo = true
    }
  }

  homeTelephoneValidOrNot(){
    let key = this.section3Form.controls['homeTelephoneNo'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validHomeTelephoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validHomeTelephoneNo = true
      }
    }else{
      this.validHomeTelephoneNo = true
    }
  }

  homeTelephone1ValidOrNot(){
    let key = this.section3Form.controls['homeTelephoneNo1'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validHome1TelephoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validHome1TelephoneNo = true
      }
    }else{
      this.validHome1TelephoneNo = true
    }
  }

  initializeForm() {
    this.section3Form = new FormGroup({
      "country" : new FormControl('',Validators.required),
      "state" : new FormControl('',Validators.required),
      "address" : new FormControl(null,Validators.required),
      "zipCode" : new FormControl(null,Validators.required),
      "telephoneNo" : new FormControl(null,Validators.required),
      "contactPhoneNo" : new FormControl(null,Validators.required),
      "email" : new FormControl(null,[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "checkForHomeAddress" : new FormControl(false,Validators.required),
      "homeAddress" : new FormControl(null,Validators.required),
      "homeTelephoneNo" : new FormControl(null,Validators.required),
      "homeTelephoneNo1" : new FormControl(null,Validators.required),
      "homeEmail" : new FormControl(null,[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
    })
    this.service.getCountryStates().subscribe((res: any) => {
      this.countryList = res
      if(this.service.formDetails.section3){
        var States = []
        States = this.countryList.filter((res) => res.country == this.service.formDetails.section3.country)
        this.stateList = States[0].states;
        this.shortName = (this.countryList.find((x)=>x.country === this.service.formDetails.section3.country)).shortName
        this.section3Form.setValue(this.service.formDetails.section3)
      }
    })
    if(this.checkRead) {
      this.section3Form.disable()
    }
  }

  getState(event){
    this.section3Form.patchValue({
      state : ''
    })
    var States = []
    States = this.countryList.filter((res) => res.country === event.target.value)
    this.stateList = States[0].states;
    this.shortName = (this.countryList.find((x)=>x.country === event.target.value)).shortName
  }

  checkForAddress(event){
    console.log("-->",event.srcElement.checked)
    if(event.srcElement.checked){
      let address;
      if(this.section3Form.value.address) {
        address = this.section3Form.value.address
      }

      if(this.section3Form.value.state) {
        address = address + ',' +this.section3Form.value.state
      }
      if(this.section3Form.value.country) {
        address = address + ',' +this.section3Form.value.country
      }
      if(this.section3Form.value.zipCode) {
        address = address + ',' +this.section3Form.value.zipCode
      }
      
      this.section3Form.patchValue({
        homeAddress : address ,
        homeTelephoneNo : this.section3Form.value.telephoneNo ? this.section3Form.value.telephoneNo : null,
        homeTelephoneNo1 : this.section3Form.value.contactPhoneNo ? this.section3Form.value.contactPhoneNo : null,
        homeEmail : this.section3Form.value.email ? this.section3Form.value.email : null,
      })
    }else{
      this.section3Form.patchValue({
        homeAddress : null,
        homeTelephoneNo : null,
        homeTelephoneNo1 : null,
        homeEmail : null
      })
    }
  }

  continue(type){
    this.submitted = true;
    this.section3Form.markAllAsTouched();
    if(this.section3Form.invalid){
      return false
    }
    this.fillForm(type)
  }

  fillForm(type){

    this.service.formDetails['section3'] = {...this.section3Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section4'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section3'] = {...this.section3Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('addressDetails')
  }
}