import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section6',
  templateUrl: './section6.component.html',
  styleUrls: ['./section6.component.css']
})
export class Section6Component implements OnInit {
  @Input() checkRead: any=false;
  submitted :boolean = false;
  section6Form :FormGroup;
  section1Data:any;
  section2Data:any
  section3Data:any
  section4Data:any
  academicQualificationFormDto:any = []
  section5Data: any;
  maxDate: string;
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter<string>();
  universityList: any=[];


  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm();
    this.dateValidation()
  }

  // to validate form 
  dateValidation() {
    let date = new Date();
    let day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
    let year = date.getFullYear();
    this.maxDate = (year) + '-' + month + '-' + day;
  }
  
  initializeForm() {
    this.section6Form = new FormGroup({
      title : new FormControl(null,Validators.required),
      subject : new FormControl(null,Validators.required),
      date : new FormControl(null,Validators.required),
      proffessionalBody : new FormControl(null),
    })
    if(this.service.formDetails.section6){
      this.section6Form.setValue({
        title : this.service.formDetails.section6.title,
        subject : this.service.formDetails.section6.subject,
        date : this.service.formDetails.section6.date,
        proffessionalBody : this.service.formDetails.section6.proffessionalBody,
      })
    }
    if(this.checkRead == true) {
      this.section6Form.disable()
    }
  }

  continue(type) {
    this.section6Form.markAllAsTouched()
    if(this.section6Form.invalid){
      return false
    }
    this.fillForm(type)
  }

  onSkip() {
    this.service.formDetails['section6'] = {}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.router.navigate(['section7'])
  }

  fillForm(type){
    this.service.formDetails['section6'] = {...this.section6Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section7'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section6'] = {...this.section6Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('professionalQualification')
  }
}