import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $:any
@Component({
  selector: 'app-section2',
  templateUrl: './section2.component.html',
  styleUrls: ['./section2.component.css']
})
export class Section2Component implements OnInit {

  section2Form:FormGroup;
  countryList:any = [];
  @Input() preview: any=false;
  @Input() checkRead: any=false;
  @Output() messageToEmit = new EventEmitter<string>();


  maxDate: any='';
  minDate: any='';
  universityList: any=[];

  constructor(private service:ServicesService,private router:Router) {  
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    }  
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): [] 
  }

  ngOnInit() {
    this.dateValidation()
    this.service.getNationality().subscribe((res: any) => {
      this.countryList = res
    })
    this.initializeForm()
    window.scrollTo(0, 0);
  }

  //------------------------------------------------------------Manage Date Validation-----------------------------------------------------------------------
  dateValidation() {
    let date = new Date();
    let day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
    let year = date.getFullYear();
    this.maxDate = (year - 5) + '-'  + month + '-' +  day;
    this.minDate = (year-80) + '-' + month + '-' + day;
  }

  onTitleSelect(val) {
    if(val == 'Other') {
      this.section2Form.get('otherTitle').setValidators([Validators.required])
    }else {
      this.section2Form.get('otherTitle').setValidators(null); 
      this.section2Form.get('otherTitle').setErrors(null);
    }
    this.section2Form.controls['otherTitle'].updateValueAndValidity();
  } 


  initializeForm(){
    this.section2Form = new FormGroup({
      "title" : new FormControl('',[Validators.required]),
      "otherTitle" : new FormControl(''),
      "surName" : new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "foreName" : new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "prefferedName" : new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "dateOfBirth" : new FormControl('',[Validators.required]),
      "gender" : new FormControl('',[Validators.required]),
      "email" : new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "confirmEmail" : new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "CountryOfBirth" : new FormControl('',[Validators.required]),
      "permanentResidenceCountry" : new FormControl('',[Validators.required]),
      "passportNumber" : new FormControl(''),
      "requireVisaForUK" : new FormControl('',[Validators.required]),
      "ethnicity" : new FormControl(''),
      "disability" : new FormControl('false',[Validators.required]),
      "descriptionForDisablity" : new FormControl('',),
      "criminalConviction" : new FormControl('false',[Validators.required]),
      "descriptionForCriminalConviction" : new FormControl('',),
    })

    if(this.service.formDetails.section2){
      this.section2Form.patchValue(this.service.formDetails.section2)
    }    
    if(this.checkRead == true) {
      this.section2Form.disable()
    }
  }

  get f() { return this.section2Form.controls; }

  continue(type){
    this.section2Form.markAllAsTouched();
    if (this.section2Form.invalid) {
      return;
    }
    this.fillForm(type)
  }

  fillForm(type){
    this.service.formDetails['section2'] = {...this.section2Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section3'])
      return;
    }
    
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

 

  // on selection of disability
  onDisabilitySelect(value) {
    value == 'true' ? this.section2Form.get('descriptionForDisablity').setValidators([Validators.required]) :   this.section2Form.get('descriptionForDisablity').setValidators(null);  this.section2Form.get('descriptionForDisablity').setErrors(null)
    this.section2Form.controls['descriptionForDisablity'].updateValueAndValidity();
  }

  // on selection of criminal conviction
  onCriminalConvictionSelect(value) {
    value == 'true' ? this.section2Form.get('descriptionForCriminalConviction').setValidators([Validators.required]) :   this.section2Form.get('descriptionForCriminalConviction').setValidators(null);  this.section2Form.get('descriptionForCriminalConviction').setErrors(null)
    this.section2Form.controls['descriptionForCriminalConviction'].updateValueAndValidity();
  }

  onPreviewSave() {
    this.service.formDetails['section2'] = {...this.section2Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('personalDetails')
  }

 
}