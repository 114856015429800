import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section11',
  templateUrl: './section11.component.html',
  styleUrls: ['./section11.component.css']
})
export class Section11Component implements OnInit {

  section11Form:FormGroup
  researchForPGR:boolean = false
  @Input() preview: any=false;
  @Input() checkRead: any=false;

  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];


  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
  }
  

  initializeForm(){
    this.section11Form = new FormGroup({
      researchProposal: new FormControl('false',Validators.required),
      researchProposalDescription: new FormControl('')
    })
    if(this.checkRead == true) {
      this.section11Form.disable()
    }
    if(this.service.formDetails.section11){
      this.section11Form.patchValue({
        researchProposal: this.service.formDetails.section11.researchProposal,
        researchProposalDescription: this.service.formDetails.section11.researchProposalDescription
      })
    }
  }

  // on external funding change
  onProposalChange(val) {
    if(val == 'true') {
      this.section11Form.get('researchProposalDescription').setValidators([Validators.required]);
    }else {
      this.section11Form.get('researchProposalDescription').setValue(null);
      this.section11Form.get('researchProposalDescription').setValidators(null);
      this.section11Form.get('researchProposalDescription').setErrors(null);
    }
    this.section11Form.controls['researchProposalDescription'].updateValueAndValidity();
  }

  continue(type){
    this.section11Form.markAllAsTouched()
    if(this.section11Form.invalid){
      return false;
    }    
    this.fillForm(type)
  }

  fillForm(type){
    this.service.formDetails['section11'] = {...this.section11Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      if(this.service.formDetails.section1.courseName == 'MBA')  {
        this.router.navigate(['section12'])
      }else if(this.service.formDetails.section1.courseName == 'Executive MBA') {
        this.router.navigate(['section14'])
      }else  {
        this.router.navigate(['section15'])
      }
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section11'] = {...this.section11Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('researchProposal')
  }
}