import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section5',
  templateUrl: './section5.component.html',
  styleUrls: ['./section5.component.css']
})
export class Section5Component implements OnInit {

  section5Form :FormGroup;
  countryList:any = []
  globalEnglishAcademicList:any = []
  globalEnglishQualificationList:any = []
  resultTypeList:any = []
  section1Data: any;
  section2Data: any;
  section3Data: any;
  resultType: any = 'text';
  section4Data: any;
  academicQualificationFormDto: any = [];
  selectedEnglishAcademicQualification: any=[];
  selectedResultTypeArr: any=[];
  selectedGlobalEnglishAcademicCountry: any=[];
  maxDate: string;
  individualArr: any=[];
  overallResultArr: any=[];
  overallArr: any[];
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter<string>();
  @Input() checkRead: any=false;
  universityList: any=[];



  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    this.service.getCountryStates().subscribe((res: any) => {
      this.countryList = res
    })
    window.scrollTo(0, 0);
    this.initializeForm()
    this.gethighestAcedemicQualification()
    this.dateValidation();
  }

  // to validate form 
  dateValidation() {
    let date = new Date();
    let day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
    let year = date.getFullYear();
    this.maxDate = (year) + '-' + month + '-' + day;
  }
  
  initializeForm(){
      this.section5Form = new FormGroup({
          "nativeOfEnglishSpeakingCountry" : new FormControl("",Validators.required),
          "nativeEnglishSpeakingCountryName" : new FormControl(""),
          "englishLanguageTestDetails" : new FormControl(""),
          "professionalQualification" : new FormControl("",Validators.required),
          "englishQualificationName" : new FormControl(""),
          "englishQualificationDate" : new FormControl(''),
          "englishQualificationScore1" : new FormControl(null),
          "englishQualificationScore2" : new FormControl(null),
          "englishQualificationScore3" : new FormControl(null),
          "englishQualificationScore4" : new FormControl(null),
          "highestAcedemicQualification" : new FormControl(''),
          "typeOfEnglishQualification" : new FormControl(''),
          "resultType" : new FormControl(""),
          "overallResult" : new FormControl(""),
          "overallBandScore" : new FormControl(null),
          "trfNo" : new FormControl(null),
      })
      if(this.checkRead == true) {
        this.section5Form.disable()
      }
      this.getAdminData()   
  }

  patchFormValues() {
    this.section5Form.patchValue({
      "nativeOfEnglishSpeakingCountry" :  this.service.formDetails.section5.nativeOfEnglishSpeakingCountry,
      "nativeEnglishSpeakingCountryName" :  this.service.formDetails.section5.nativeEnglishSpeakingCountryName,
      "professionalQualification" :  this.service.formDetails.section5.professionalQualification
    })

    if(this.service.formDetails.section5.professionalQualification == "false") {
      this.section5Form.patchValue({
        "highestAcedemicQualification" :  this.service.formDetails.section5.highestAcedemicQualification
      })
      // this.onSelectEnglishProfessional(this.)
      // To set type of english qualification
      this.selectedEnglishAcademicQualification =[]
      this.globalEnglishAcademicList.forEach(ele => {
        if(ele.country == this.service.formDetails.section5.highestAcedemicQualification) {
          this.selectedEnglishAcademicQualification.push(ele)
        }
      });

      this.section5Form.patchValue({
        "typeOfEnglishQualification" :  this.service.formDetails.section5.typeOfEnglishQualification  
      })

      //To set result type
      let obj1 = this.selectedEnglishAcademicQualification.find(x=>x.englishAcademicQualificationCode == this.service.formDetails.section5.typeOfEnglishQualification);
      this.selectedResultTypeArr = []
      if(obj1.resultTypeList) {
        this.resultTypeList.forEach(ele => {
          if(obj1.resultTypeList.includes(ele.resultName)) {
            this.selectedResultTypeArr.push(ele)
          }
        });
      }
      this.section5Form.patchValue({
        "resultType" :  this.service.formDetails.section5.resultType
      })

      // To set overall result
      let data = this.resultTypeList.filter(x => x.resultName == this.service.formDetails.section5.resultType)
      this.resultType = data[0].datatype
      let obj = data[0]
      this.overallResultArr = []
      for(let i = Number(obj.minRanange) ; i <= Number(obj.maxRange) ; i = i + Number(obj.overAllInterval) ) {
        this.overallResultArr.push(Number(i))
      }
      this.section5Form.patchValue({
        "overallResult" :  Number(this.service.formDetails.section5.overallResult)
      })
      
    }else {
      this.section5Form.patchValue({
        "englishQualificationName" :  this.service.formDetails.section5.englishQualificationName,
        "englishQualificationDate" :  this.service.formDetails.section5.englishQualificationDate,
      })
      let obj = this.globalEnglishQualificationList.find(x=>x.professionalQualificationName == this.service.formDetails.section5.englishQualificationName)
      this.individualArr = []
      for(let i = Number(obj.individualRangeFrom) ; i <= Number(obj.individualRangeTo); i= i+Number(obj.individualRangeInterval)) {
        this.individualArr.push(Number(i).toFixed(1))
      }
      this.overallArr = []
      for(let i = Number(obj.overAllRangeFrom) ; i <= Number(obj.overAllRangeTo); i= i+Number(obj.overAllInterval)) {
        this.overallArr.push(Number(i).toFixed(1))
      }
      this.section5Form.patchValue({
        "englishQualificationScore1" :  Number(this.service.formDetails.section5.englishQualificationScore1).toFixed(1),
        "englishQualificationScore2" :  Number(this.service.formDetails.section5.englishQualificationScore2).toFixed(1),
        "englishQualificationScore3" :  Number(this.service.formDetails.section5.englishQualificationScore3).toFixed(1),
        "englishQualificationScore4" :  Number(this.service.formDetails.section5.englishQualificationScore4).toFixed(1), 
        "overallBandScore" :  Number(this.service.formDetails.section5.overallBandScore).toFixed(1),
        "trfNo" :  Number(this.service.formDetails.section5.trfNo)
      })
    }

  }

  getAdminData(){
    this.service.showSpinner()
    this.service.getApi(`course/get-search-all-global-english-professional-details?page=0&pagesize=999&isBand=false`,1).subscribe((res:any) => {
      if(res.body.status == 200){
        console.log("res-->",res.body.data.getAllData.content)
        this.globalEnglishQualificationList = res.body.data.getAllData.content
        this.globalEnglishQualificationList.sort((a,b) => (a.professionalQualificationName > b.professionalQualificationName) ? 1 : ((b.professionalQualificationName > a.professionalQualificationName) ? -1 : 0))
        if(this.service.formDetails.section5 &&  this.globalEnglishQualificationList.length > 0 && this.resultTypeList.length > 0 && this.globalEnglishAcademicList.length > 0){
          this.patchFormValues()
        }   
      }
    })
    this.service.getApi(`course/get-allData-serchByName?page=0&pageSize=9999`,1).subscribe((res:any) => {
      if(res.body.status == 200){
        this.resultTypeList = res.body.data.allData.content
        if(this.service.formDetails.section5 &&  this.globalEnglishQualificationList.length > 0 && this.resultTypeList.length > 0 && this.globalEnglishAcademicList.length > 0){
          this.patchFormValues()
        }  
      }
    })
    setTimeout(e => {
      this.service.hideSpinner()
    },3000)
  }

  gethighestAcedemicQualification(){
    this.service.showSpinner()
    this.service.getApi(`course/get-search-all-global-english-academic-details?page=0&pagesize=9999&isband=false`,1).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.body.status == 200){
        this.globalEnglishAcademicList = res.body.data.getAllData.content
        this.globalEnglishAcademicList.forEach(element => {
          this.selectedGlobalEnglishAcademicCountry.push(element.country)
        });
        this.selectedGlobalEnglishAcademicCountry = [... new Set(this.selectedGlobalEnglishAcademicCountry)]
        this.selectedGlobalEnglishAcademicCountry.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
        if(this.service.formDetails.section5 &&  this.globalEnglishQualificationList.length > 0 && this.resultTypeList.length > 0 && this.globalEnglishAcademicList.length > 0){
          this.patchFormValues()
        }  
      }
    })
  }

  onCountrySelect(value) {
    this.selectedEnglishAcademicQualification =[]
    this.globalEnglishAcademicList.forEach(ele => {
      if(ele.country == value) {
        this.selectedEnglishAcademicQualification.push(ele)
      }
    });
    this.selectedEnglishAcademicQualification.sort((a,b) => (a.academicQualification > b.academicQualification) ? 1 : ((b.academicQualification > a.academicQualification) ? -1 : 0))

    // let obj = this.globalEnglishAcademicList.find(x=>x.country == value);
    // this.selectedEnglishAcademicQualification = obj.resultTypeList.split(',')
  }

  onSelectEnglishAcademicQualification(value) {
    console.log(value,this.selectedEnglishAcademicQualification)

    let obj = this.selectedEnglishAcademicQualification.find(x=>x.englishAcademicQualificationCode == value);
    this.selectedResultTypeArr = []
    if(obj.resultTypeList) {
      this.resultTypeList.forEach(ele => {
        if(obj.resultTypeList.includes(ele.resultName)) {
          this.selectedResultTypeArr.push(ele)
        }
      });
    }
  }

  changeResult(event){
    let data = this.resultTypeList.filter(x => x.resultName == event.target.value)
    this.resultType = data[0].datatype
    let obj = data[0]
    this.overallResultArr = []
    for(let i = Number(obj.minRanange) ; i <= Number(obj.maxRange) ; i = i + Number(obj.overAllInterval) ) {
      this.overallResultArr.push(Number(i))
    }
  }

  continue(type){
    this.section5Form.markAllAsTouched()
    if (this.section5Form.invalid) {
      return false
    }
    this.fillForm(type)
  }

  selectNativeOfEnglishSpeakingCountry(event){
    let value = event.target.value
    value == 'false' ? this.section5Form.patchValue({'nativeEnglishSpeakingCountryName' : ''}): '';
    value == 'true' ? this.section5Form.get('nativeEnglishSpeakingCountryName').setValidators([Validators.required]) :   this.section5Form.get('nativeEnglishSpeakingCountryName').setValidators(null);  this.section5Form.get('nativeEnglishSpeakingCountryName').setErrors(null)
    this.section5Form.controls['nativeEnglishSpeakingCountryName'].updateValueAndValidity();
  }

  fillForm(type){
    this.service.formDetails['section5'] = {...this.section5Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section6'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  // on selection of professional qualification
  onSelectProfessionalQualification(val) {
    if(val == "true") {
      this.section5Form.get('englishQualificationName').setValidators([Validators.required]) 
      this.section5Form.get('englishQualificationDate').setValidators([Validators.required]) 
      this.section5Form.get('englishQualificationScore1').setValidators([Validators.required]) 
      this.section5Form.get('englishQualificationScore2').setValidators([Validators.required]) 
      this.section5Form.get('englishQualificationScore3').setValidators([Validators.required]) 
      this.section5Form.get('englishQualificationScore4').setValidators([Validators.required]) 
      this.section5Form.get('overallBandScore').setValidators([Validators.required]) 
      this.section5Form.get('trfNo').setValidators([Validators.required]) 
    }else {
      this.section5Form.get('englishQualificationName').setValue(null);
      this.section5Form.get('englishQualificationName').setValidators(null);
      this.section5Form.get('englishQualificationName').setErrors(null)
      this.section5Form.get('englishQualificationDate').setValue(null);
      this.section5Form.get('englishQualificationDate').setValidators(null);
      this.section5Form.get('englishQualificationDate').setErrors(null)
      this.section5Form.get('englishQualificationScore1').setValue(null);
      this.section5Form.get('englishQualificationScore1').setValidators(null);
      this.section5Form.get('englishQualificationScore1').setErrors(null)
      this.section5Form.get('englishQualificationScore2').setValue(null);
      this.section5Form.get('englishQualificationScore2').setValidators(null);
      this.section5Form.get('englishQualificationScore2').setErrors(null)
      this.section5Form.get('englishQualificationScore3').setValue(null);
      this.section5Form.get('englishQualificationScore3').setValidators(null);
      this.section5Form.get('englishQualificationScore3').setErrors(null)
      this.section5Form.get('englishQualificationScore4').setValue(null);
      this.section5Form.get('englishQualificationScore4').setValidators(null);
      this.section5Form.get('englishQualificationScore4').setErrors(null);
      this.section5Form.get('overallBandScore').setValue(null);
      this.section5Form.get('overallBandScore').setValidators(null);
      this.section5Form.get('overallBandScore').setErrors(null);
      this.section5Form.get('trfNo').setValue(null);
      this.section5Form.get('trfNo').setValidators(null);
      this.section5Form.get('trfNo').setErrors(null);

    }
    this.section5Form.controls['englishQualificationName'].updateValueAndValidity();
    this.section5Form.controls['englishQualificationDate'].updateValueAndValidity();
    this.section5Form.controls['englishQualificationScore1'].updateValueAndValidity();
    this.section5Form.controls['englishQualificationScore2'].updateValueAndValidity();
    this.section5Form.controls['englishQualificationScore3'].updateValueAndValidity();
    this.section5Form.controls['englishQualificationScore4'].updateValueAndValidity();
    this.section5Form.controls['overallBandScore'].updateValueAndValidity();
    this.section5Form.controls['trfNo'].updateValueAndValidity();

    if(val == "false") {
      this.section5Form.get('highestAcedemicQualification').setValidators([Validators.required]) 
      this.section5Form.get('typeOfEnglishQualification').setValidators([Validators.required]) 
      this.section5Form.get('resultType').setValidators([Validators.required]) 
      this.section5Form.get('overallResult').setValidators([Validators.required]) 
    }else {
      this.section5Form.get('highestAcedemicQualification').setValue(null);
      this.section5Form.get('highestAcedemicQualification').setValidators(null);
      this.section5Form.get('highestAcedemicQualification').setErrors(null);
      this.section5Form.get('typeOfEnglishQualification').setValue(null);
      this.section5Form.get('typeOfEnglishQualification').setValidators(null);
      this.section5Form.get('typeOfEnglishQualification').setErrors(null);
      this.section5Form.get('resultType').setValue(null);
      this.section5Form.get('resultType').setValidators(null);
      this.section5Form.get('resultType').setErrors(null);
      this.section5Form.get('overallResult').setValue(null);
      this.section5Form.get('overallResult').setValidators(null);
      this.section5Form.get('overallResult').setErrors(null);
    }

    this.section5Form.controls['highestAcedemicQualification'].updateValueAndValidity();
    this.section5Form.controls['typeOfEnglishQualification'].updateValueAndValidity();
    this.section5Form.controls['resultType'].updateValueAndValidity();
    this.section5Form.controls['overallResult'].updateValueAndValidity();
  }

  onSelectEnglishProfessional(name) {
    let obj = this.globalEnglishQualificationList.find(x=>x.professionalQualificationName == name)
    console.log(obj)
    this.individualArr = []
    for(let i = Number(obj.individualRangeFrom) ; i <= Number(obj.individualRangeTo); i= i+Number(obj.individualRangeInterval)) {
      this.individualArr.push(Number(i).toFixed(1))
    }
    this.overallArr = []
    for(let i = Number(obj.overAllRangeFrom) ; i <= Number(obj.overAllRangeTo); i= i+Number(obj.overAllInterval)) {
      this.overallArr.push(Number(i).toFixed(1))
    }
  }

  onPreviewSave() {
    this.service.formDetails['section5'] = {...this.section5Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('englishQualification')
  }
}