import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any;
@Component({
  selector: 'app-section7',
  templateUrl: './section7.component.html',
  styleUrls: ['./section7.component.css']
})
export class Section7Component implements OnInit {
  @Input() checkRead: any=false;

  section7Form :FormGroup;
  workExperience:boolean = false
  submitted:boolean = false
  section1Data :any
  section2Data :any
  section3Data :any
  section4Data :any
  section5Data :any
  section6Data :any
  academicQualificationFormDto :any = []
  maxDate: string;
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];


  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
    this.dateValidation()
  }

  // to validate form 
  dateValidation() {
    let date = new Date();
    let day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
    let year = date.getFullYear();
    this.maxDate = (year) + '-' + month + '-' + day;
  }
  

  initializeForm() {
    this.section7Form = new FormGroup({
      "workExperience" : new FormControl("no"),
      "totalExperience" : new FormControl(""),
      "employersName" : new FormControl(null),
      "employersBusiness" : new FormControl(null),
      "jobTitle" : new FormControl(null),
      "briefDuties" : new FormControl(null),
      "employedFrom" : new FormControl(null),
      "employedTo" : new FormControl(null),
      "annualSalary" : new FormControl(null),
      "employersAddress" : new FormControl(null),
      "work_email" : new FormControl('',[Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "achievements" : new FormControl(null),
    })
    if(this.checkRead == true) {
      this.section7Form.disable()
    }
    if(this.service.formDetails.section7){
      this.section7Form.setValue({
        "workExperience" : this.service.formDetails.section7.workExperience,
        "totalExperience" : this.service.formDetails.section7.totalExperience,
        "employersName" : this.service.formDetails.section7.employersName,
        "employersBusiness" : this.service.formDetails.section7.employersBusiness,
        "jobTitle" : this.service.formDetails.section7.jobTitle,
        "briefDuties" : this.service.formDetails.section7.briefDuties,
        "employedFrom" : this.service.formDetails.section7.employedFrom,
        "employedTo" : this.service.formDetails.section7.employedTo,
        "annualSalary" : this.service.formDetails.section7.annualSalary,
        "employersAddress" : this.service.formDetails.section7.employersAddress,
        "work_email" : this.service.formDetails.section7.work_email,
        "achievements" : this.service.formDetails.section7.achievements,
      })
      this.change(this.service.formDetails.section7.workExperience)
    }
  }

  change(event){
    this.workExperience = event;
    if(event == "yes"){
      this.addCustomValidation()
    }else{
      this.removeCustomValidation()
    }
  }

  // to add custom avalidation for work experience
  addCustomValidation() {
    this.section7Form.get('totalExperience').setValidators([Validators.required]) 
    this.section7Form.get('employersName').setValidators([Validators.required]) 
    this.section7Form.get('employersBusiness').setValidators([Validators.required]) 
    this.section7Form.get('jobTitle').setValidators([Validators.required]) 
    this.section7Form.get('briefDuties').setValidators([Validators.required]) 
    this.section7Form.get('employedFrom').setValidators([Validators.required]) 
    this.section7Form.get('employedTo').setValidators([Validators.required]) 
    this.section7Form.get('annualSalary').setValidators([Validators.required]) 
    this.section7Form.get('employersAddress').setValidators([Validators.required]) 
    this.section7Form.get('work_email').setValidators([Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]) 
    this.section7Form.get('achievements').setValidators([Validators.required]) 
  }

  // to remove custom avalidation for work experience
  removeCustomValidation() {
    this.section7Form.get('totalExperience').setValidators(null);
    this.section7Form.get('totalExperience').setErrors(null);
    this.section7Form.controls['totalExperience'].updateValueAndValidity();
    this.section7Form.get('employersName').setValidators(null);
    this.section7Form.get('employersName').setErrors(null);
    this.section7Form.controls['employersName'].updateValueAndValidity();
    this.section7Form.get('employersBusiness').setValidators(null);
    this.section7Form.get('employersBusiness').setErrors(null);
    this.section7Form.controls['employersBusiness'].updateValueAndValidity();
    this.section7Form.get('jobTitle').setValidators(null);
    this.section7Form.get('jobTitle').setErrors(null);
    this.section7Form.controls['jobTitle'].updateValueAndValidity();
    this.section7Form.get('briefDuties').setValidators(null);
    this.section7Form.get('briefDuties').setErrors(null);
    this.section7Form.controls['briefDuties'].updateValueAndValidity();
    this.section7Form.get('employedFrom').setValidators(null);
    this.section7Form.get('employedFrom').setErrors(null);
    this.section7Form.controls['employedFrom'].updateValueAndValidity();
    this.section7Form.get('employedTo').setValidators(null);
    this.section7Form.get('employedTo').setErrors(null);
    this.section7Form.controls['employedTo'].updateValueAndValidity();
    this.section7Form.get('annualSalary').setValidators(null);
    this.section7Form.get('annualSalary').setErrors(null);
    this.section7Form.controls['annualSalary'].updateValueAndValidity();
    this.section7Form.get('employersAddress').setValidators(null);
    this.section7Form.get('employersAddress').setErrors(null);
    this.section7Form.controls['employersAddress'].updateValueAndValidity();
    this.section7Form.get('work_email').setValidators(null);
    this.section7Form.get('work_email').setErrors(null);
    this.section7Form.controls['work_email'].updateValueAndValidity();
    this.section7Form.get('achievements').setValidators(null);
    this.section7Form.get('achievements').setErrors(null);
    this.section7Form.controls['achievements'].updateValueAndValidity();
  }

  continue(type){
    this.section7Form.markAllAsTouched();
    if(this.section7Form.invalid){
      return false
    }
    this.fillForm(type)
  }


  fillForm(type){
    this.service.formDetails['section7'] = {...this.section7Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section8'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section7'] = {...this.section7Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('pgtEmployment')
  }
}