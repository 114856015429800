import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class ServicesService {
  httpOptions: any={ observe: 'response'       };
  baseUrl :any
      // representativeBaseUrl = 'http://localhost:4201/';
      // webSiteUrl = "http://localhost:4500/"
      representativeBaseUrl :any;
      webSiteUrl :any;
      studentBaseUrl: any;
      formDetails:any = {}
      profileObj: any={};
      adminEmail = 'myanambakkam@hotmail.com'
      constructor(private http:HttpClient,private toastr:ToastrService,private spinner: NgxSpinnerService) { 
        this.profileObj = localStorage.getItem('myProfile') ? JSON.parse(localStorage.getItem('myProfile')) : {}
        // if(localStorage.getItem('formDetails')) {
        //   this.formDetails = JSON.parse(localStorage.getItem('formDetails'))
        // }
        let url = window.location.href
        if(url.includes('repstag')) {
            this.baseUrl = "https://try.readme.io/http://apistag.univ.global:2001/";
            this.representativeBaseUrl = 'https://repstag.univ.global/';
            this.studentBaseUrl = 'https://stustag.univ.global/';
            this.webSiteUrl = 'https://webstag.univ.global/'
        }else if(url.includes('localhost')){
            // this.baseUrl = "https://try.readme.io/http://ec2-3-22-2-94.us-east-2.compute.amazonaws.com:2001/";
            // this.baseUrl = "https://try.readme.io/http://api.univ.global:2001/";
            this.baseUrl = "https://try.readme.io/http://apistag.univ.global:2001/";
            this.representativeBaseUrl = 'https://repstag.univ.global/';
            this.studentBaseUrl = 'https://stustag.univ.global/';
            this.webSiteUrl = 'https://webstag.univ.global/'
        }else {
            this.baseUrl = "https://try.readme.io/http://api.univ.global:2001/";
            this.representativeBaseUrl = 'https://rep.univ.global/';
            this.studentBaseUrl = 'https://student.univ.global/';
            this.webSiteUrl = 'https://univ.global/'

        }
      }
      
      
      
  
  
  //================ POST API =========================//
      postApi(url, data, Header) { 
        console.log('url---->')
            let httpOptions;
            if (Header == 1) {
              httpOptions = {
                headers: new HttpHeaders({
                  "Content-Type": "application/json",
                  "Authorization":"Bearer "+localStorage.getItem('token'),
                  "userId": "Bearer "+localStorage.getItem('token')
                }),
                observe: 'response'              
              }     
            }
            else {
              httpOptions = {
                headers: new HttpHeaders({
                  "Content-Type": "application/json",
                }),
                observe: 'response'              
              }            
            }
          return this.http.post((this.baseUrl+ url), data, httpOptions)
      }
    
  //================ GET API =========================//    
      getApi(url, isHeader) {
        // console.log("token -->",localStorage.getItem('token'))
          let httpOptions;
          if (isHeader == 1) {
            httpOptions = {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization":"Bearer "+localStorage.getItem('token'),
              }),
              observe: 'response'              
            }     
          }
          else {
            httpOptions = {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
              }),
              observe: 'response'              
            }            
          }
          // console.log("header-->",httpOptions)
          return this.http.get((this.baseUrl + url), httpOptions)
      }


      getAPI(url):Observable<any> {
          if (localStorage.getItem('token')) {
            this.httpOptions['headers'] =  new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization":"Bearer "+localStorage.getItem('token')
              })  
            }     
          return this.http.get((this.baseUrl + url), this.httpOptions)
      }
  
  //================ PUT API =========================//
      putApi(url,data:any){
          var headers;
          let accountDetails = localStorage.getItem('myProfile') ? JSON.parse(localStorage.getItem('myProfile')) : {}
          this.httpOptions['headers']  = new HttpHeaders({
              "token": localStorage.getItem('token'),
              "Authorization":"Bearer "+localStorage.getItem('token'),
              'userId': accountDetails.userId
          })
          console.log(headers)
          return this.http.put(this.baseUrl + url,data,this.httpOptions)
      }
  
  //================ DELETE API =========================//
      deleteApi(url) {
          let headers;
          headers = new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization":"Bearer "+localStorage.getItem('token')
          })     
          return this.http.delete(this.baseUrl + url, headers);
      }
  
      //================ SHOW SPINNER =========================//
    showSpinner() {
      this.spinner.show();
    }
      
    //================ HIDE SPINNER =========================//
      hideSpinner() {    
          this.spinner.hide();
      }

      showSuccess(msg) {
          this.toastr.success(msg);
      }
  
      toastErr(msg) {   
        this.toastr.error(msg)
      }

 
  
  //================ HIDE Succes TOASTR =========================//
      getCountryStates(): Observable<any> {
        return this.http.get("./assets/json/countrystateList.json");
      }

      getNationality(): Observable<any> {
        return this.http.get("./assets/json/nationality.json");
      }


    postMethodMultipart(url, data): Observable<any> {
        let headers;
        headers = new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        });
        return this.http.post(this.baseUrl + url, data, { headers });
      }
}


/*********************************** INTERCEPTOR *****************************************/

@Injectable()
export class HttpModifierInterceptor implements HttpInterceptor {
    constructor(private router: Router, private service: ServicesService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(res => {
            if (res instanceof HttpResponse) {
                if (res.status !== 200) {
                  this.service.hideSpinner()
                    setTimeout(x => {
                    }, 5000);
                }
            }
        }, err => {
          this.service.hideSpinner()
            if (err instanceof HttpErrorResponse) {
                if (err.error.status === 401) {
                    localStorage.clear()
                    
                    this.router.navigateByUrl('')
                    setTimeout(x => {
                    }, 5000);
                    this.router.navigateByUrl('login');
                } 
                // else {
                //     localStorage.clear()
                //     this.service.hideSpinner()
                //     this.router.navigateByUrl('login');
                //     setTimeout(x => {
                //     }, 500);
                // }
            }
        }));

    }
}
