import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any
@Component({
  selector: 'app-section12',
  templateUrl: './section12.component.html',
  styleUrls: ['./section12.component.css']
})
export class Section12Component implements OnInit {

  section12Form:FormGroup;
  @Input() preview: any=false;
  @Input() checkRead: any=false;

  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];


  constructor(private router:Router,private service:ServicesService) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
  }

  initializeForm(){
    this.section12Form = new FormGroup({
      "graduateWorkExperience" : new FormControl(null,Validators.required),
      "totalExperience" : new FormControl(null,Validators.required),
      "autonomyAndResponsibility" : new FormControl(null,Validators.required),
      "technicalExpertKnowledge" : new FormControl(null,Validators.required),
      "contributions" : new FormControl(null,Validators.required),
      "keyDecision" : new FormControl(null,Validators.required),
      "managingProjects" : new FormControl(null,Validators.required),
      "managingBudget" : new FormControl(null,Validators.required),
      "evidenceOfCarrerProgession" : new FormControl(null,Validators.required),
    })
    if(this.checkRead == true) {
      this.section12Form.disable()
    }
    if(this.service.formDetails.section12){
      this.section12Form.setValue({
        "graduateWorkExperience" :  this.service.formDetails.section12.graduateWorkExperience,
        "totalExperience" :  this.service.formDetails.section12.totalExperience,
        "autonomyAndResponsibility" :  this.service.formDetails.section12.autonomyAndResponsibility,
        "technicalExpertKnowledge" :  this.service.formDetails.section12.technicalExpertKnowledge,
        "contributions" :  this.service.formDetails.section12.contributions,
        "keyDecision" :  this.service.formDetails.section12.keyDecision,
        "managingProjects" :  this.service.formDetails.section12.managingProjects,
        "managingBudget" :  this.service.formDetails.section12.managingBudget,
        "evidenceOfCarrerProgession" :  this.service.formDetails.section12.evidenceOfCarrerProgession,
      })
    }
  }
 
  continue(type){
    this.section12Form.markAllAsTouched()
    if(this.section12Form.invalid){
      return false
    }
    this.fillForm(type)
  }

  fillForm(type){
    this.service.formDetails['section12'] = {...this.section12Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section13'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section12'] = {...this.section12Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('fullTimeMBA')
    console.log('tested')
  }
}