import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-section15',
  templateUrl: './section15.component.html',
  styleUrls: ['./section15.component.css']
})
export class Section15Component implements OnInit {
  fileData: any;
  section15Form:FormGroup;
  fileNameObj:any = {
    'personalStatement': [],
    'researchPersonal': [], 
    'cv': [],
    'transcript': [],
    'englishLanguageCertificate': [],
    'englishProfessionalCertificate': [],
    'workExperience': [],
    'letterOfReference': [],
    'otherDocument': [],
  }
  @Input() checkRead: any=false;

  count: number=0;
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  universityList: any=[];

  

  constructor(public service :ServicesService,private router:Router) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'));
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

    if(this.service.formDetails.documents) {
      this.fileNameObj = this.service.formDetails.documents;
    }
    
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
    // if(JSON.parse(localStorage.getItem('section1')).searchCourse.toLowerCase().includes('mba')){
    //     this.mbaExist = true;
    //     if(JSON.parse(localStorage.getItem('section1')).searchCourse.toLowerCase().includes('executive')){
    //         this.executiveMbaExist = true;
    //     }
    // }
  }
  

  initializeForm(){
    this.section15Form = new FormGroup({
      "personalStatement" : new FormControl(null),
      "researchPersonal" : new FormControl(null),
      "cv" : new FormControl(null),
      "transcript" : new FormControl(null),
      "degreeCertificate" : new FormControl(null),
      "englishLanguageCertificate" : new FormControl(null),
      "englishProfessionalCertificate" : new FormControl(null),
      "otherDocument" : new FormControl(null),
      "workExperience" : new FormControl(null),
      "letterOfReference" : new FormControl(null),
      "disclaimer" : new FormControl(null),
      "checkForDisclaimer" : new FormControl(false),
    })
    if(this.checkRead == true) {
      this.section15Form.disable()
    }
    if(this.service.formDetails.documents) {
      this.section15Form.patchValue({checkForDisclaimer: true})
    }
    if(this.service.formDetails.documents) {
      this.section15Form.patchValue({checkForDisclaimer: true})
    }
  }


  handleFileInput(event,key) {
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.fileData = event.target.files[0];
      var FileSize = this.fileData.size / 1024 / 1024; // in MB
        if (FileSize > 2) {
            $('#exceedStorage').modal('show')
        }else{
          this.count = [...event.target.files].length
          this.getDocumentsToUpload(key,[...event.target.files] )
        }
    }
  }

  getDocumentsToUpload(key,file) {
    console.log(this.count, key, file)
    if(this.count != 0) {
      this.uploadFile(key,file)
    } 
    if(this.count == 0) {
      console.log(this.fileNameObj[key])
    }
  }

  uploadFile(key,file) {
    var formData = new FormData()
    formData.append('file', this.fileData)
    this.service.showSpinner()
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      if(res.status == 200) {
        this.service.hideSpinner()
        this.fileNameObj[key].push({
          'name': file[this.count-1].name,
          'file': res.data
        })
        this.section15Form.reset()
        this.service.showSuccess(this.fileNameObj[key].length + ' file(s) uploaded successfully!')
        setTimeout(() => {
          this.count = this.count - 1;
          this.getDocumentsToUpload(key,file)
        }, 2000);
      }
    })
  }

  onDeleteIcon(index,key) {
    this.fileNameObj[key].splice(index,1);
    this.section15Form.reset()
    this.service.toastErr('File Removed!')
  }


  previewNow(type){
    this.section15Form.markAllAsTouched()
    if(this.fileNameObj.personalStatement.length == 0) {
      this.service.toastErr('Please upload personal statement document !');
      return
    }
    if(this.fileNameObj.cv.length == 0) {
      this.service.toastErr('Please upload curriculum vitae document !');
      return
    }
    if(this.fileNameObj.transcript.length == 0) {
      this.service.toastErr('Please upload transcript document !');
      return
    }
    if(this.service.formDetails.section5.professionalQualification == 'true' && this.fileNameObj.englishProfessionalCertificate.length == 0) {
      this.service.toastErr('Please upload english professional cerificate !');
      return
    }
    if(this.service.formDetails.section5.professionalQualification == 'false' && this.fileNameObj.englishLanguageCertificate.length == 0) {
      this.service.toastErr('Please upload english language cerificate !');
      return
    }
    if(this.service.formDetails.section11.researchProposal == 'true' && this.fileNameObj.researchPersonal.length == 0) {
      this.service.toastErr('Please upload research proposal !');
      return
    }
    if(this.section15Form.value.checkForDisclaimer != true){
      return false
    }
    this.service.formDetails['documents'] = {...this.fileNameObj}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next') {
      this.router.navigateByUrl('application-form-preview')
      return;
    }

    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['documents'] = {...this.fileNameObj}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('docUpload')
  }

  checkCondition() {
      if(this.service.formDetails.section1.courseName == 'MBA')  {
        this.router.navigate(['section13'])
      }else if(this.service.formDetails.section1.courseName == 'Executive MBA') {
        this.router.navigate(['section14'])
      }else  {
        this.router.navigate(['section11'])
      }
    }
}