import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
declare var $:any

@Component({
  selector: 'app-section14',
  templateUrl: './section14.component.html',
  styleUrls: ['./section14.component.css']
})
export class Section14Component implements OnInit {

  section14Form :FormGroup
  @Input() preview: any=false;
  @Output() messageToEmit = new EventEmitter();
  @Input() checkRead: any=false;
  universityList: any=[];



  constructor(private router:Router,private service:ServicesService) { 
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
    this.universityList = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm()
  }
  

  initializeForm(){
    this.section14Form = new FormGroup({
        "fullTimeManagerialExperience" : new FormControl(null,Validators.required),
        "totalExperience" : new FormControl(null,Validators.required),
        "involmentInStrategicPlanning" : new FormControl(null,Validators.required),      
        "coordinatingInTeam" : new FormControl(null,Validators.required),
        "meetingFinancialTargets": new FormControl(null,Validators.required),
        "requirementForWorkActivities" : new FormControl(null,Validators.required),
        "responsibilityForImprovingPerfomance" : new FormControl(null,Validators.required),
        "primarilyResultsThroughInfluencing" : new FormControl(null,Validators.required),
        "experienceOfInternallyWorking" : new FormControl(null,Validators.required),
    })
    if(this.checkRead == true) {
      this.section14Form.disable()
    }
    if(this.service.formDetails.section14){
        this.section14Form.patchValue({
            "fullTimeManagerialExperience" :  this.service.formDetails.section14.fullTimeManagerialExperience,
            "totalExperience" :  this.service.formDetails.section14.totalExperience,
            "involmentInStrategicPlanning" :  this.service.formDetails.section14.involmentInStrategicPlanning,
            "coordinatingInTeam" :  this.service.formDetails.section14.coordinatingInTeam,
            "meetingFinancialTargets":  this.service.formDetails.section14.meetingFinancialTargets,
            "requirementForWorkActivities" :  this.service.formDetails.section14.requirementForWorkActivities,
            "responsibilityForImprovingPerfomance" :  this.service.formDetails.section14.responsibilityForImprovingPerfomance,
            "primarilyResultsThroughInfluencing" :  this.service.formDetails.section14.primarilyResultsThroughInfluencing,
            "experienceOfInternallyWorking" :  this.service.formDetails.section14.experienceOfInternallyWorking,
        })
    }
  }


  continue(type){
    this.section14Form.markAllAsTouched()
    if(this.section14Form.invalid){
      return false
    }
    this.fillForm(type)
  }

  fillForm(type){
    this.service.formDetails['section14'] = {...this.section14Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    if(type == 'next'){
      this.router.navigate(['section15'])
      return;
    }
    let req= {}
    req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
    req['courseId'] = this.service.formDetails.section1.subjectCode;
    req['courseName'] = this.service.formDetails.section1.courseName;
    req['currentStatus'] = 'INCOMPLETE';
    req['formData'] = JSON.stringify(this.service.formDetails);
    req['representativeEmail'] = this.service.formDetails.representativeEmail;
    req['representativeName'] = this.service.formDetails.representativeName;
    req['formMatchingDto'] = []
    // to form university data
    this.universityList.forEach(element => {
      if(element.selected == true) {
        req['formMatchingDto'].push({
          "status": "ASC_P",
          "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
          "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
          // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
          // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
          "universityEmail": element.universityEmail,
          "universityId":  element.fkUniversityId,
          "universityName": element.universityName
        })
      }
    });
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      localStorage.removeItem('formId');
      localStorage.removeItem('formDetails')
      $('#exampleModalCenter').modal('show')
    })
  }

  onPreviewSave() {
    this.service.formDetails['section14'] = {...this.section14Form.value}
    localStorage.setItem('formDetails',JSON.stringify(this.service.formDetails))
    this.service.showSuccess('Saved Successfully')
    this.messageToEmit.emit('executiveMBA')
  }
}